import React from "react";

const Header = ({children}) => {
  return (
    <div className="mx-auto grid grid-cols-8 gap-4 place-content-end w-[100%] aspect-[4/1] max-h-96 bg-cover bg-top-left bg-no-repeat rounded-lg" style={{ backgroundImage: 'url(ma-banner.png)' }}>     
      {children}
    </div>
  );
};


export default Header;
